import React, { Fragment, Component } from 'react';
import { Alert, Row, Col } from 'reactstrap';
import { PageTitle } from '../layout-components/PageTitle';
import { VideoList, SeanceWellness, SeanceBrainTransformation, SeanceRemovingLocks, SeanceWater } from './video/Watch';
import { Seo } from '../seo'

export class Seance extends Component {
    componentDidMount() {
        Seo({
            title: 'Видеосеансы Николая Левашова'
        });
    }

    render() {

        function Description() {
            return (
                <div className="text-justify">
                    Здесь выложены несколько видеосеансов, которые оказывают оздоровительное и общеукрепляющее воздействие на организм просматривающего их зрителя.
                    Видеосеансы выложены для бесплатного копирования и использования.
                    Безусловно, они не являются панацеей от всех бед, но те мои читатели, которые имеют проблемы со здоровьем и не в состоянии заплатить за индивидуальное лечение, и окажутся достаточно восприимчивыми для воздействия видеосеанса, смогут избавиться от многих проблем со здоровьем, просматривая эти сеансы.
                    А тем, у кого чувствительность низкая, стоит повторять циклы сеансов многократно, до появления ожидаемого эффекта (причины этого изложены в Правилах). Настоятельно рекомендую, перед просмотром, внимательно прочитать Правила (они – очень простые и понятные) и не забывать соблюдать их.
                </div>
            );
        }

        return (
            <Fragment>
                <PageTitle
                    titleHeading="Видеосеансы"
                    titleDescription={ <Description /> }
                />

                <Row className="no-gutters w-100">
                    <Col xl="8" lg="12">
                        <Alert className="mb-4" color="danger">
                            <h5 className="alert-heading text-center mt-2 mb-3"><b>Правила просмотра</b></h5>
                            <div className="text-justify">
                                <p className="mb-2">Сила воздействия рассчитана на средний уровень восприятия человека сидящего в зале,  и в записи сеансы могут действовать слабее.</p>
                                <p>Люди с повышенной чувствительностью могут чувствовать воздействие очень сильно, а люди с пониженной – слабо или не ощущать воздействие вообще.</p>
                                <hr />
                                <p className="mb-2">В случае, если люди с повышенной чувствительностью почувствуют признаки головокружения или тошноты, необходимо немедленно прекратить просмотр сеанса.</p>
                                <p className="mb-2">Данные симптомы означают перегрузку организма и настоятельно рекомендуется не продолжать просмотр, иначе принимающий сеанс может войти в состояние, близкое к коматозному.</p>
                                <p>Даже если во время просмотра кто-то не будет испытывать практически ничего, то это не значит что сеанс не действует - воздействие на организм может проявиться позже.</p>
                            </div>
                        </Alert>
                    </Col>
                    <VideoList title='Оздоровительные сеансы' link='seance' items={SeanceWellness} />
                    <VideoList title='Преобразование мозга' link='seance' items={SeanceBrainTransformation} />
                    <VideoList title='Снятие блокировок' link='seance' items={SeanceRemovingLocks} />
                    <VideoList title='Зарядить воду' link='seance' items={SeanceWater} />
                </Row>
            </Fragment>
        );
    }
}