import React, { Fragment, Component } from 'react';
import { Row } from 'reactstrap';
import { PageTitle } from '../layout-components/PageTitle';
import { VideoList, VideoFilming } from './video/Watch';
import { Seo } from '../seo'

function Description() {
    return (
        <div className="text-justify">
            «Духовное и нравственное развитие не связано с возрастом человека
            или его образованием, а является отражением его уровня
            развития, которое определяется его деяниями и пониманием»
        </div>
    );
}

export class Filming extends Component {
    componentDidMount() {
        Seo({
            title: 'Экранизации знаний Николая Левашова'
        });
    }

    render() {
        return (
            <Fragment>
                <PageTitle
                    titleHeading="Видеофильмы"
                    titleDescription={<Description />}
                />

                <Row className="no-gutters w-100">
                    <VideoList title='Фильмы на основе знаний Николая Левашова' link='filming' items={VideoFilming} />
                </Row>
            </Fragment>
        );
    }
}