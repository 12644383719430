import React, { Fragment } from 'react';
import { Verse } from './Verse';

export function VerseBreatheFreely() {
    return (
        <Verse
            title="Вздохни свободно"
            description="Николай Левашов, 7 ноября 2010 года."
            content={
                <Fragment>
                    <div className="mt-2 text-center">
                        Вздохни свободно,<br/>
                        Родная земля<br/>
                        Оковами лжи<br/>
                        Сковали тебя.
                        <p/>
                        Враги взбеленились,<br/>
                        Пытаясь убить<br/>
                        Источник живой,<br/>
                        Что в сердце журчит.
                        <p />
                        Проснись ото сна,<br/>
                        Родная земля.<br/>
                        Ты детям своим<br/>
                        Сегодня нужна.
                        <p />
                        Дай силы<br/>
                        Врага победить.<br/>
                        И славу и честь<br/>
                        Тебе возвратить.
                        <p />
                        Враги сняли маски – <br/>
                        Нет смысла уж боле.<br/>
                        Им лик неприглядный<br/>
                        Под маской скрывать.
                        <p />
                        И лик сей ужасный,<br/>
                        Кой долго скрывали,<br/>
                        Узрели народы<br/>
                        И поняли суть.
                        <p />
                        Настал час расплаты<br/>
                        За все злодеянья,<br/>
                        И всё ради злата<br/>
                        И алчных желаний.
                        <p />
                        Богатства души<br/>
                        Променяли вороги,<br/>
                        На блеск мишуры<br/>
                        И тупик по дороге.
                        <p />
                        Продали за злато<br/>
                        Враги свои души.<br/>
                        И кровью детей<br/>
                        Окропили ту плату.
                        <p />
                        А кровью других<br/>
                        Залили всю землю.<br/>
                        По воле чужих<br/>
                        Превращая в пустыню.
                        <p />
                        В рабов превратив<br/>
                        Другие народы.<br/>
                        Забыв навсегда<br/>
                        Про законы природы.
                        <p />
                        Окутав дурманом<br/>
                        Сознанье людское,<br/>
                        Считая бараном<br/>
                        Любого другого.
                        <p />
                        И слепо поверив<br/>
                        В абсурдность такого,<br/>
                        Мечтали на веки<br/>
                        Царить на той крови.
                        <p />
                        Но кривда не может<br/>
                        Скрываться за правдой.<br/>
                        И чёрт не поможет<br/>
                        Остаться такою.
                        <p />
                        Настал час рассвета,<br/>
                        И тьма отступает.<br/>
                        Не выдержать света<br/>
                        И... свет наступает!
                        <p />
                        Свет знаний раскроет<br/>
                        Глаза тем народам,<br/>
                        Что спали столетья<br/>
                        В том страшном дурмане.
                        <p />
                        И спящий проснётся – <br/>
                        Разбудит другого!<br/>
                        И каждый очнётся<br/>
                        От бреда такого.
                        <p />
                        Рассеется тьма,<br/>
                        И свет воссияет.<br/>
                        Отступит чума,<br/>
                        И радость настанет.
                        <p />
                        Но это случится,<br/>
                        Коль дружно все станем<br/>
                        Бороться с той тьмой<br/>
                        И душой все восстанем.
                        <p />
                        Когда всё поймём – <br/>
                        С колен сами встанем!<br/>
                        И силы найдём – <br/>
                        Страх рабский оставим!
                        <p />
                    </div>
                </Fragment>
            }
        />
    );
}