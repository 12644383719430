import React, { Fragment } from 'react';
import { Verse } from './Verse';

export function VerseOldVictories() {
    return (
        <Verse
            title="Старые победы"
            description="Николай Левашов, 14 мая 2009 года."
            content={
                <Fragment>
                    <div className="mt-2 text-center">
                        Победы предков всех пугали,<br/>
                        Боялись все сынов Богов.<br/>
                        Своею кровью русы землю поливали,<br/>
                        Её спасая от врагов!
                        <p />
                        Ах, русы, русы просыпайтесь,<br/>
                        В вас тоже кровь Богов течёт!<br/>
                        Ах, русы, русы поднимайтесь<br/>
                        Пора отчизне сбросить гнёт!
                        <p />
                        Враги украли старые победы,<br/>
                        В которых память прошлого живёт!<br/>
                        Взамен подсунув ложь и беды,<br/>
                        Народ в которых до сих пор живёт!
                        <p />
                        Следы величья уничтожив,<br/>
                        Враги решили взяться за народ!<br/>
                        И миллионы уничтожив,<br/>
                        Считали мёртвым Света Род.
                        <p />
                        Но рано празднуют победы,<br/>
                        Живёт и дышит наш народ!<br/>
                        Отторгнет ложь, осилит беды,<br/>
                        Несущий свет другим народ!
                        <p />
                        Победы славные былые,<br/>
                        К победам новым приведут!<br/>
                        И русы станут все иные,<br/>
                        И славу снова обретут!
                        <p />
                        Ярмо рабов не красит русов,<br/>
                        Оно обманом было внесено!<br/>
                        Обман и ложь – оружье трусов,<br/>
                        В ком совесть умерла давно!
                        <p />
                        Враги все битвы проиграли,<br/>
                        Не в силах русов силой одолеть.<br/>
                        Победу только ложью одержали,<br/>
                        Заставив слабости жалеть.
                        <p />
                        Что только слабый и ущербный,<br/>
                        Духовность в муках обретёт!<br/>
                        Когда оставит мир сей бренный,<br/>
                        В загробной жизни счастие найдёт!
                        <p />
                        Не верьте, русы, этому обману!<br/>
                        Души свобода в Ваших же руках.<br/>
                        Не поддавайтесь Вы дурману,<br/>
                        Блуждать довольно в адовых кругах!
                        <p />
                        Встряхните это наважденье,<br/>
                        Раскройте силы собственной души.<br/>
                        Внутри себя найдите вдохновенье<br/>
                        Во славу собственной страны!
                        <p />
                        Никто другой не в силах это сделать – <br/>
                        ни Бог, ни царь и не герой!<br/>
                        В единстве русов сила возрожденья<br/>
                        Сварожьей утренней порой!
                        <p />
                        Проснись от спячки, славный русич,<br/>
                        тебе пора других будить!<br/>
                        Проснись от спячки, светлый русич,<br/>
                        Пора отчизну возродить...
                        <p />
                    </div>
                </Fragment>
            }
        />
    );
}