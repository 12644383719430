import React, { Fragment } from 'react';
import { observer } from "mobx-react";
import clsx from 'clsx';

import { SidebarHeader } from './SidebarHeader';
import { SidebarMenu } from './SidebarMenu';

import { sidebarStore } from './SidebarStore';

export const Sidebar = observer(() => {
    return (
        <Fragment>
            <div className="app-sidebar app-sidebar--dark">
                <SidebarHeader />
                <div className="app-sidebar--content">
                    <SidebarMenu />
                </div>
            </div>
            <div
                onClick={sidebarStore.toggleSidebarMobile}
                className={clsx('app-sidebar-overlay', {
                    'is-active': sidebarStore.sidebarToggleMobile
                })}>
            </div>
        </Fragment>
    );
});
