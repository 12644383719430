import React, { Fragment, Component } from 'react';
import clsx from 'clsx';
import { Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { sidebarStore } from './SidebarStore';

export class Header extends Component {
    toggleSidebarMobile = () => {
        let { sidebarToggleMobile, setSidebarToggleMobile } = this.props;
        setSidebarToggleMobile(!sidebarToggleMobile);
    };

    render() {
        return (
            <Fragment>
                <div className="app-header">
                    <div className="app-header--pane">
                        <button
                            className={
                                clsx('navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
                                    { 'is-active': sidebarStore.sidebarToggleMobile })
                            }
                            onClick={sidebarStore.toggleSidebarMobile}>
                            <span className="hamburger-box">
                                <span className="hamburger-inner" />
                            </span>
                        </button>

                        <Nav className="ml-3">
                            <UncontrolledDropdown nav>
                                <DropdownToggle nav caret className="rounded-sm">
                                    Путеводитель
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem tag={Link} to="/">Вступление</DropdownItem>
                                    <DropdownItem tag={Link} to="/advices">Рекомендации</DropdownItem>
                                    <DropdownItem tag={Link} to="/books">Книги и статьи</DropdownItem>
                                    <DropdownItem tag={Link} to="/filming">Видеофильмы</DropdownItem>
                                    <DropdownItem tag={Link} to="/seance">Видеосеансы</DropdownItem>
                                    <DropdownItem tag={Link} to="/video">Видеозаписи</DropdownItem>
                                    <DropdownItem tag={Link} to="/source">Источник Жизни</DropdownItem>
                                    <DropdownItem tag={Link} to="/painting">Живопись</DropdownItem>
                                    <DropdownItem tag={Link} to="/verses">Стихи</DropdownItem>
                                    {/*<DropdownItem divider />*/}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                    </div>
                    <div className="app-header--pane">
                        <Nav>
                            <NavItem>
                                <NavLink tag={Link} to="/" className="rounded-sm">
                                    Вступление
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                </div>
            </Fragment>
        );
    }
}
