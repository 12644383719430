import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import projectLogo from '../assets/images/favicon.svg';
import { sidebarStore } from './SidebarStore';

export class SidebarHeader extends Component {
    render() {
        return (
            <Fragment>
                <div className="app-sidebar--header">
                    <div className="nav-logo">
                        <button
                            className={
                                clsx('mr-3 navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
                                    { 'is-active': sidebarStore.sidebarToggleMobile })
                            }
                            onClick={sidebarStore.toggleSidebarMobile}>
                            <span className="hamburger-box">
                                <span className="hamburger-inner" />
                            </span>
                        </button>
                        <Link to="/" title="собственное имя Achird">
                            <i>
                                <img
                                    src={projectLogo}
                                />
                            </i>
                            <span>Николай Левашов</span>
                        </Link>
                    </div>
                </div>
            </Fragment>
        );
    }
}
