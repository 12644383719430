import React, { Component, Fragment } from 'react';
import { Row } from 'reactstrap';
import { PageTitle } from '../layout-components/PageTitle';
import { Seo } from '../seo'
import { VerseRevelationsOfTheUniverses } from './verses/VerseRevelationsOfTheUniverses';
import { VerseDedicatedToTheMemoryOfSvetlana } from './verses/VerseDedicatedToTheMemoryOfSvetlana';
import { VerseSleepingRussia } from './verses/VerseSleepingRussia';
import { VerseOldVictories } from './verses/VerseOldVictories'
import { VerseBreatheFreely } from './verses/VerseBreatheFreely'
import { VerseWakeUpRussiansAndPeoples } from './verses/VerseWakeUpRussiansAndPeoples'
import { VerseHeartBleeding } from './verses/VerseHeartBleeding'
import { VerseYouAreMyBelovedOne } from './verses/VerseYouAreMyBelovedOne'

export class Verses extends Component {
    componentDidMount() {
        Seo({
            title: 'Стихи Николая Левашова'
        });
    }

    render() {

        function Quote() {
            return (
                <div>
                    Свой хрупкий чёлн в познанья океан<br/>
                    Я бросил смело, полный дерзновенья,<br />
                    И получил я всё, что ожидал,<br />
                    И сверх того – Вселенных откровенья.
                </div>
            );
        }

        return (
            <Fragment>
                <PageTitle
                    titleHeading="Стихи"
                    titleDescription="Здесь представлены стихи Николая Левашова"
                    titleQuote={<Quote />}
                />

                <Fragment>
                    <Row>
                        <VerseRevelationsOfTheUniverses />
                        <VerseDedicatedToTheMemoryOfSvetlana />
                        <VerseSleepingRussia />
                        <VerseOldVictories />
                        <VerseBreatheFreely />
                        <VerseWakeUpRussiansAndPeoples />
                        <VerseHeartBleeding />
                        <VerseYouAreMyBelovedOne />
                    </Row>
                </Fragment>
            </Fragment>
        );
    }
}