import React, { Fragment } from 'react';
import { Verse } from './Verse';

export function VerseWakeUpRussiansAndPeoples() {
    return (
        <Verse
            title="Проснитесь, русы и народы!"
            description="Николай Левашов, 9 июня 2011 года."
            content={
                <Fragment>
                    <div className="mt-2 text-center">
                        Проснитесь, русы и народы – <br/>
                        Ведь вместе били всех врагов!<br/>
                        И брали блага от природы<br/>
                        И вместе славили Богов.
                        <p/>
                        Богами были наши предки,<br/>
                        Учили жить по совести они.<br/>
                        Свободу, честь ценили выше жизни,<br/>
                        А рабству смерть готовы были предпочти.
                        <p />
                        Куда ушло величье наших предков?!<br/>
                        Откуда рабский дух в душе?!<br/>
                        Кто превратил народы в овнов<br/>
                        И хочет в жертву принести?!
                        <p />
                        Враги пролили реки крови,<br/>
                        Пытаясь душу русскую убить.<br/>
                        Лишили всех свободы, воли,<br/>
                        Заставив долю рабскую влачить.
                        <p />
                        Чтоб каждый рус душою умер,<br/>
                        Чтоб свет очей потух, исчез!<br/>
                        Чтоб стержень треснул и навеки,<br/>
                        Сам русский дух с Земли исчез.
                        <p />
                        Тот самый дух, что в сказках был подмечен,<br/>
                        Тот самый дух, что в битвах побеждал!<br/>
                        Тот самый дух, что славой был отмечен,<br/>
                        Дела Великие свершал!
                        <p />
                        Но Тьмой окутанный надолго<br/>
                        И погружённый в долгий сон,<br/>
                        Он выжил и уже проснулся,<br/>
                        И набирает силу он!
                        <p />
                        Пускай проснулись только единицы!<br/>
                        Их завтра будет тысячи уже!<br/>
                        И от границы до границы,<br/>
                        Проснётся русский дух во всей своей красе!
                        <p />
                        И скинет сонные оковы<br/>
                        И сотворит великие дела!<br/>
                        Вернёт свободу и другим народам<br/>
                        И возродится вся Земля!
                        <p />
                    </div>
                </Fragment>
            }
        />
    );
}