import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Switch, Route, Redirect } from 'react-router';

import { Layout } from './Layout';
import MinimalLayout from './MinimalLayout';
import { Home } from './components/Home';
import { Advices } from './components/Advices';
import { Verses } from './components/Verses';
import { Books } from './components/Books';
import { Painting } from './components/Painting';
import { Video } from './components/Video';
import { Filming } from './components/Filming';
import { Seance } from './components/Seance';
import { SourceOfLife } from './components/SourceOfLife';
import { Watch } from './components/video/Watch';
import { Page404 } from './components/Page404'
import ScrollToTop from './ScrollToTop';

import "./mobxConfig";
import "./fontawesomeConfig";
import './assets/base.scss';

export default class App extends Component {
    render() {
        return (
            <BrowserRouter>
                <ScrollToTop>
                    <Switch>
                        <Redirect exact from="/" to="/home" />
                        <Route
                            path={[
                                '/home',
                                '/advices',
                                '/books',
                                '/verses',
                                '/painting',
                                '/seance',
                                '/video',
                                '/filming',
                                '/source',
                                '/watch/:link/:id'
                            ]}>
                            <Layout>
                                <Route path='/home' component={Home} />
                                <Route path='/advices' component={Advices} />
                                <Route path='/books' component={Books} />
                                <Route path='/verses' component={Verses} />
                                <Route path='/painting' component={Painting} />
                                <Route path='/seance' component={Seance} />
                                <Route path='/video' component={Video} />
                                <Route path='/filming' component={Filming} />
                                <Route path='/source' component={SourceOfLife} />
                                <Route path='/watch/:link/:id' component={Watch} />
                            </Layout>
                        </Route>
                        <Route path={['/page404']}>
                            <MinimalLayout>
                                <Route path="/page404" component={Page404} />
                            </MinimalLayout>
                        </Route>
                        <Redirect to="/page404" />
                    </Switch>
                </ScrollToTop>
            </BrowserRouter>
        );
    }
}
