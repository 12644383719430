import React, { Component, Fragment } from 'react';
import { Row, Col, Button, Card } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Seo } from '../../seo';

export const VideoEvents = [
    { id: 'ucX1HClr', url: 'event_2007_06_03.mp4', poster: 'event_2007_06_03.jpg', title: '1. Встреча с читателями 03.06.2007.' },
    { id: 'YM6jeKTe', url: 'event_2007_12_15.mp4', poster: 'event_2007_12_15.jpg', title: '2. Встреча с читателями 15.12.2007.' },
    { id: 'XjdeMirC', url: 'event_2008_01_12.mp4', poster: 'event_2008_01_12.jpg', title: '3. Встреча с читателями 12.01.2008.' },
    { id: 'Vso6Gzyz', url: 'event_2008_04_19.mp4', poster: 'event_2008_04_19.jpg', title: '4. Встреча с читателями 19.04.2008.' },
    { id: 'FrXc4QUy', url: 'event_2008_05_17.mp4', poster: 'event_2008_05_17.jpg', title: '5. Встреча с читателями 17.05.2008.' },
    { id: '7cFO8sHv', url: 'event_2008_08_23.mp4', poster: 'event_2008_08_23.jpg', title: '6. Встреча с читателями 23.08.2008.' },
    { id: 'XG3vsZ0I', url: 'event_2008_09_20.mp4', poster: 'event_2008_09_20.jpg', title: '7. Встреча с читателями 20.09.2008.' },
    { id: 'kcEghPoK', url: 'event_2008_12_06.mp4', poster: 'event_2008_12_06.jpg', title: '8. Встреча с читателями 06.12.2008.' },
    { id: 'IDVmctbM', url: 'event_2009_01_24.mp4', poster: 'event_2009_01_24.jpg', title: '9. Встреча с читателями 24.01.2009.' },
    { id: 'PIUEJHHB', url: 'event_2009_04_04.mp4', poster: 'event_2009_04_04.jpg', title: '10. Встреча с читателями 04.04.2009.' },
    { id: 'VtSo5Oyl', url: 'event_2009_05_30.mp4', poster: 'event_2009_05_30.jpg', title: '11. Встреча с читателями 30.05.2009.' },
    { id: 'wdyFHcU9', url: 'event_2009_06_20.mp4', poster: 'event_2009_06_20.jpg', title: '12. Встреча с читателями 20.06.2009.' },
    { id: 'xThoNodI', url: 'event_2009_07_25.mp4', poster: 'event_2009_07_25.jpg', title: '13. Встреча с читателями 25.07.2009.' },
    { id: 'yzFw09jA', url: 'event_2009_08_22.mp4', poster: 'event_2009_08_22.jpg', title: '14. Встреча с читателями 22.08.2009.' },
    { id: 'QTZrkqnF', url: 'event_2009_10_31.mp4', poster: 'event_2009_10_31.jpg', title: '15. Встреча с читателями 31.10.2009.' },
    { id: 'A4ypujXj', url: 'event_2009_12_05.mp4', poster: 'event_2009_12_05.jpg', title: '16. Встреча с читателями 05.12.2009.' },
    { id: 'Od4uY7sK', url: 'event_2010_01_23.mp4', poster: 'event_2010_01_23.jpg', title: '17. Встреча с читателями 23.01.2010.' },
    { id: 'drMCfTIm', url: 'event_2010_04_24.mp4', poster: 'event_2010_04_24.jpg', title: '18. Встреча с читателями 24.04.2010.' },
    { id: 'DSDzOQz5', url: 'event_2010_05_22.mp4', poster: 'event_2010_05_22.jpg', title: '19. Встреча с читателями 22.05.2010.' },
    { id: 'b1b1Z0tf', url: 'event_2010_07_24.mp4', poster: 'event_2010_07_24.jpg', title: '20. Встреча с читателями 24.07.2010.' },
    { id: 'gNI95Jv8', url: 'event_2010_08_21.mp4', poster: 'event_2010_08_21.jpg', title: '21. Встреча с читателями 21.08.2010.' },
    { id: 'lJ0Y2IAe', url: 'event_2010_09_25.mp4', poster: 'event_2010_09_25.jpg', title: '22. Встреча с читателями 25.09.2010.' },
    { id: 'n4bkemuY', url: 'event_2010_10_23.mp4', poster: 'event_2010_10_23.jpg', title: '23. Встреча с читателями 23.10.2010.' },
    { id: 'mwkOUmGu', url: 'event_2010_12_04.mp4', poster: 'event_2010_12_04.jpg', title: '24. Встреча с читателями 04.12.2010.' },
    { id: 'no4aVSDe', url: 'event_2010_12_25.mp4', poster: 'event_2010_12_25.jpg', title: '25. Встреча с читателями 25.12.2010.' },
    { id: 'njILEKs4', url: 'event_2011_01_29.mp4', poster: 'event_2011_01_29.jpg', title: '26. Встреча с читателями 29.01.2011.' },
    { id: 'DlxWkZbd', url: 'event_2011_02_26.mp4', poster: 'event_2011_02_26.jpg', title: '27. Встреча с читателями 26.02.2011.' },
    { id: 'rCA8tsJT', url: 'event_2011_04_02.mp4', poster: 'event_2011_04_02.jpg', title: '28. Встреча с читателями 02.04.2011.' },
    { id: '61dLRsxq', url: 'event_2011_05_28.mp4', poster: 'event_2011_05_28.jpg', title: '29. Встреча с читателями 28.05.2011.' },
    { id: 'eoz7qkU3', url: 'event_2011_06_25.mp4', poster: 'event_2011_06_25.jpg', title: '30. Встреча с читателями 25.06.2011.' },
    { id: 'cjnxKFIV', url: 'event_2011_07_23.mp4', poster: 'event_2011_07_23.jpg', title: '31. Встреча с читателями 23.07.2011.' },
    { id: '3BrlyQbJ', url: 'event_2011_09_03.mp4', poster: 'event_2011_09_03.jpg', title: '32. Встреча с читателями 03.09.2011.' },
    { id: 'gpXmv5pN', url: 'event_2011_10_08.mp4', poster: 'event_2011_10_08.jpg', title: '33. Встреча с читателями 08.10.2011.' },
    { id: 'Kbk5UwQC', url: 'event_2011_11_05.mp4', poster: 'event_2011_11_05.jpg', title: '34. Встреча с читателями 05.11.2011.' },
    { id: 'bX9VPF1n', url: 'event_2011_12_10.mp4', poster: 'event_2011_12_10.jpg', title: '35. Встреча с читателями 10.12.2011.' },
    { id: 'RsXgAgJN', url: 'event_2012_01_28.mp4', poster: 'event_2012_01_28.jpg', title: '36. Встреча с читателями 28.01.2012.' },
    { id: 'DSlXX0pi', url: 'event_2012_02_25.mp4', poster: 'event_2012_02_25.jpg', title: '37. Встреча с читателями 25.02.2012.' },
    { id: 'PhOaktIG', url: 'event_2012_03_31.mp4', poster: 'event_2012_03_31.jpg', title: '38. Встреча с читателями 31.03.2012.' },
];
export const VideoMeetings = [
    { id: 'V64fDhAf', url: 'meeting_with_participants_2007_06_23.mp4', poster: 'meeting_with_participants_2007_06_23.jpg', title: '1. Встреча с участниками Движения 23.06.2007.' },
    { id: 'vZ4qJ9vs', url: 'meeting_with_participants_2007_09_08.mp4', poster: 'meeting_with_participants_2007_09_08.jpg', title: '2. Встреча с участниками Движения 08.09.2007.' },
    { id: 'qzf4nX5j', url: 'meeting_with_participants_2008_02_15.mp4', poster: 'meeting_with_participants_2008_02_15.jpg', title: '3. Встреча с участниками Движения 15.02.2008.' },
    { id: '4PyYmhd7', url: 'meeting_with_participants_2008_03_22.mp4', poster: 'meeting_with_participants_2008_03_22.jpg', title: '4. Встреча с участниками Движения 22.03.2008.' },
    { id: 'xudj2XEs', url: 'meeting_with_participants_2008_05_31.mp4', poster: 'meeting_with_participants_2008_05_31.jpg', title: '5. Встреча с участниками Движения 31.05.2008.' },
    { id: 'ru3DJcVG', url: 'meeting_with_participants_2008_07_05.mp4', poster: 'meeting_with_participants_2008_07_05.jpg', title: '6. Встреча с участниками Движения 05.07.2008.' },
    { id: 'zL9Yz4Xj', url: 'meeting_with_participants_2008_10_18.mp4', poster: 'meeting_with_participants_2008_10_18.jpg', title: '7. Встреча с участниками Движения 18.10.2008.' },
    { id: 'mnQ3M4bg', url: 'meeting_with_participants_2008_11_08.mp4', poster: 'meeting_with_participants_2008_11_08.jpg', title: '8. Встреча с участниками Движения 08.11.2008.' },
    { id: 'BxKp8xqY', url: 'meeting_with_participants_2008_12_20.mp4', poster: 'meeting_with_participants_2008_12_20.jpg', title: '9. Встреча с участниками Движения 20.12.2008.' },
    { id: 'Dgd35sRy', url: 'meeting_with_participants_2009_02_14.mp4', poster: 'meeting_with_participants_2009_02_14.jpg', title: '10. Встреча с участниками Движения 14.02.2009.' },
    { id: 'TJ9w7cLB', url: 'meeting_with_participants_2009_07_04.mp4', poster: 'meeting_with_participants_2009_07_04.jpg', title: '11. Встреча с участниками Движения 04.07.2009.' },
    { id: 'DWnfN9mk', url: 'meeting_with_participants_2009_08_08.mp4', poster: 'meeting_with_participants_2009_08_08.jpg', title: '12. Встреча с участниками Движения 08.08.2009.' },
    { id: 'K3ACmaby', url: 'meeting_with_participants_2009_09_19.mp4', poster: 'meeting_with_participants_2009_09_19.jpg', title: '13. Встреча с участниками Движения 19.09.2009.' },
    { id: 'zFq4Nkzx', url: 'meeting_with_participants_2009_10_23.mp4', poster: 'meeting_with_participants_2009_10_23.jpg', title: '14. Встреча с участниками Движения 23.10.2009.' },
    { id: 'yZzhCR4Z', url: 'meeting_with_participants_2010_01_30.mp4', poster: 'meeting_with_participants_2010_01_30.jpg', title: '15. Встреча с участниками Движения 30.01.2010.' },
    { id: 'T2n5neKf', url: 'meeting_with_participants_2010_06_19.mp4', poster: 'meeting_with_participants_2010_06_19.jpg', title: '16. Встреча с участниками Движения 19.06.2010.' },
    { id: '8uPFFrUd', url: 'meeting_with_participants_2010_10_02.mp4', poster: 'meeting_with_participants_2010_10_02.jpg', title: '17. Встреча с участниками Движения 02.10.2010.' },
    { id: '6yjRX7nZ', url: 'meeting_with_participants_2011_02_12.mp4', poster: 'meeting_with_participants_2011_02_12.jpg', title: '18. Встреча с участниками Движения 12.02.2011.' },
    { id: 'Dnucj5aW', url: 'meeting_with_participants_2011_09_30.mp4', poster: 'meeting_with_participants_2011_09_30.jpg', title: '19. Встреча с участниками Движения 30.09.2011.' },
];
export const VideoPresentations = [
    { id: 'KuGnEFrB', url: 'real_human_capabilities_part_1_2010_03_19.mp4', poster: 'real_human_capabilities_part_1_2010_03_19.jpg', title: '19.03.2010. Семинар-выступление Реальные возможности человека. День 1.' },
    { id: '15729Q97', url: 'real_human_capabilities_part_2_2010_03_20.mp4', poster: 'real_human_capabilities_part_2_2010_03_20.jpg', title: '20.03.2010. Семинар-выступление Реальные возможности человека. День 2.' },
    { id: '0MVLv48R', url: 'real_human_capabilities_part_3_2010_03_21.mp4', poster: 'real_human_capabilities_part_3_2010_03_21.jpg', title: '21.03.2010. Семинар-выступление Реальные возможности человека. День 3.' },
    { id: 'Rdde8W5t', url: 'presentation_in_Arkhangelsk_1991_06.mp4', poster: 'presentation_in_Arkhangelsk_1991_06.png', title: 'Семинар-выступление в Архангельске 1991 год.' },
];
export const VideoInterview = [
    { id: 'Iwqrlom6', url: 'interview_2007_06_02.mp4', poster: 'interview_2007_06_02.jpg', title: '02.06.2007 Николай Левашов на Волга-ТВ.' },
    { id: 'z9iHnR6a', url: 'interview_2008_01_15.mp4', poster: 'interview_2008_01_15.jpg', title: '15.01.2008 Выступление на телеканале ВКТ.' },
    { id: 'oMWQBzgO', url: 'interview_2010_07_04.mp4', poster: 'interview_2010_07_04.jpg', title: '04.07.2010 Неопубликованное интервью газете Нью-Йорк Таймс.' },
    { id: 'vmWj4PQ5', url: 'interview_2010_08_22.mp4', poster: 'interview_2010_08_22.jpg', title: '22.08.2010 Россия в огне. Климат как оружие. Документальный фильм Фёдора Калугина.' },
    { id: 'zCa3Rrp6', url: 'interview_2010_08_26_1.mp4', poster: 'interview_2010_08_26_1.jpg', title: '26.08.2010 Климатическая война. Интервью' },
    { id: 'js8OGbi9', url: 'interview_2010_08_26_2.mp4', poster: 'interview_2010_08_26_2.jpg', title: '26.08.2010 Климатическая война. Фильм' },
    { id: 'OrbjwjbT', url: 'interview_2010_10_06.mp4', poster: 'interview_2010_10_06.jpg', title: '06.10.2010 Актуальный разговор с Владимиром Соловьевым.' },
    { id: '3loG1TSo', url: 'interview_2010_10_21.mp4', poster: 'interview_2010_10_21.jpg', title: '21.10.2010 Налог на недвижимость. Интервью Н.В. Левашова телеканалу ТВЦ.' },
    { id: 'KEYdXXXR', url: 'interview_2011_01_28.mp4', poster: 'interview_2011_01_28.jpg', title: '28.01.2011 Апокалипсис. Гибель планеты. Телеканал ДТВ' },
    { id: 'KJum0gU6', url: 'interview_2011_02_10.mp4', poster: 'interview_2011_02_10.jpg', title: '10.02.2011 Апокалипсис. Климатический коллапс. Телеканал ТВ-3' },
    { id: '0F2zVtZl', url: 'interview_2011_02_16.mp4', poster: 'interview_2011_02_16.jpg', title: '16.02.2011 Климат. Мировая революция. Телеканал Россия-1' },
    { id: 'yIq5UaDG', url: 'interview_2011_02_17.mp4', poster: 'interview_2011_02_17.jpg', title: '17.02.2011 Интервью телеканалу РЕН.' },
    { id: 'G1haifm6', url: 'interview_2011_04_13.mp4', poster: 'interview_2011_04_13.jpg', title: '13.04.2011 Интервью телеканалу ТВЦ.' },
    { id: '9AoIz4Xm', url: 'interview_2011_04_29.mp4', poster: 'interview_2011_04_29.jpg', title: '29.04.2011 Секретные территории. НЛО. Телеканал Рен-ТВ' },
    { id: 'oGFol9bA', url: 'interview_2011_05_20.mp4', poster: 'interview_2011_05_20.jpg', title: '20.05.2011 Можно ли управлять погодой. Телеканал МИР' },
    { id: 'bUelrXoO', url: 'interview_2011_06_20.mp4', poster: 'interview_2011_06_20.jpg', title: '20.06.2011 Передача Разговор. Телеканал Столица' },
    { id: 'AOei4glu', url: 'interview_2011_12_05.mp4', poster: 'interview_2011_12_05.jpg', title: '05.12.2011 Полное интервью телеканалу НТВ.' },
    { id: 'a8wdNMIP', url: 'interview_2011_12_08.mp4', poster: 'interview_2011_12_08.jpg', title: '08.12.2011 Полное интервью телеканалу Россия-1.' },
    { id: 'laKwSou8', url: 'interview_2012_01_30.mp4', poster: 'interview_2012_01_30.jpg', title: '30.01.2012 Интервью телеканалу НТВ.' },
];
export const VideoInteresting = [
    { id: 'v1QMeeHL', url: 'stories_from_the_future.mp4', poster: 'stories_from_the_future.jpg', title: '2007 год. Физик Велихов Евгений Павлович - О темной материи.' },
    { id: 'LmmGFZZ2', url: 'cell_division.mp4', poster: 'cell_division.png', title: 'Деление клеток под микроскопом.' },
];
export const VideoFilming = [
    { id: 'K4omXRqW', url: 'great_tartary_rus_empire.mp4', poster: 'great_tartary_rus_empire.jpg', title: 'Великая Тартария – Империя Русов.' },
    { id: 'eGka2Hs0', url: 'radomir_joy_ of_the_world.mp4', poster: 'radomir_joy_ of_the_world.jpg', title: 'Радомир - Радость Мира.' },
    { id: '3Haq2VMJ', url: 'essence_in_the_cycle_of_life_and_death.mp4', poster: 'essence_in_the_cycle_of_life_and_death.jpg', title: 'Сущность в круговороте жизни и смерти.' },
    { id: 'lkaragX2', url: 'peter.mp4', poster: 'peter.jpg', title: 'Пётр и Пётр.' },
    { id: 'XBvOsJc2', url: 'holy_relics_ruined_souls.mp4', poster: 'holy_relics_ruined_souls.jpg', title: 'Святые мощи – загубленные Души.' },
    { id: 'CGA2cQc8', url: 'levashov_the_president.mp4', poster: 'levashov_the_president.jpg', title: 'ЦИК против Николая Левашова.' },
    { id: 'ap1OxUFr', url: 'lord_of_hurricanes.mp4', poster: 'lord_of_hurricanes.jpg', title: 'Повелитель ураганов.' },
];
export const SeanceWellness = [
    { id: 'B5Nc2vDH', url: 'wellness_session_2010_day_1.mp4', poster: 'wellness_session_2010_day_1.png', title: '19.03.2010. Семинар-выступление в Москве. День 1-й. Оздоровительный сеанс.' },
    { id: 'GM2yuBGc', url: 'wellness_session_2010_day_2.mp4', poster: 'wellness_session_2010_day_2.png', title: '20.03.2010. Семинар-выступление в Москве. День 2-й. Оздоровительный сеанс.' },
    { id: 'YMtGuGqT', url: 'wellness_session_2010_day_3.mp4', poster: 'wellness_session_2010_day_3.png', title: '21.03.2010. Семинар-выступление в Москве. День 3-й. Оздоровительный сеанс.' },
    { id: 'fTUTBsbJ', url: 'wellness_session_1991.mp4', poster: 'wellness_session_1991.png', title: '1991 год. Семинар-выступление в Архангельске. Оздоровительный сеанс.' },
    { id: 'pmnL8qxp', url: 'remove_overload.mp4', poster: 'remove_overload.png', title: '1991 год. Семинар-выступление в Архангельске. Снятие перегрузки после Оздоровительного сеанса.' },
];
export const SeanceBrainTransformation = [
    { id: '57LFiGWP', url: 'brain_transformation_2010_1.mp4', poster: 'brain_transformation_2010_1.png', title: '19.03.2010. Семинар-выступление в Архангельске. День 1-й. Преобразование мозга.' },
    { id: 'mZP5OGQ7', url: 'brain_transformation_2010_2.mp4', poster: 'brain_transformation_2010_2.png', title: '20.03.2010. Семинар-выступление в Архангельске. День 2-й. Преобразование мозга.' },
    { id: 'IvE60icv', url: 'brain_transformation_2010_3.mp4', poster: 'brain_transformation_2010_3.png', title: '21.03.2010. Семинар-выступление в Архангельске. День 3-й. Преобразование мозга.' },
    { id: 'am1QhuvW', url: 'brain_transformation_1991.mp4', poster: 'brain_transformation_1991.png', title: '1991 год. Семинар-выступление в Архангельске. Преобразование мозга и возвращение в нормальное состояние.' },
];
export const SeanceRemovingLocks = [
    { id: 'tVOTlo1q', url: 'removing_locks_2010_03_19.mp4', poster: 'removing_locks_2010_03_19.png', title: '19.03.2010. Семинар-выступление в Москве. Снятие блокировок.' },
    { id: 'dXY9b25C', url: 'removing_locks_2011_01_29.mp4', poster: 'removing_locks_2011_01_29.png', title: '29.01.2011. Встреча с читателями. Снятие блокировок.' },
    { id: 'R6xKHpOI', url: 'removing_locks_2012_03_31.mp4', poster: 'removing_locks_2012_03_31.png', title: '31.03.2012. Встреча с читателями. Снятие блокировок.' },
    { id: 'fPqrACM0', url: 'consequences_of_abortion.mp4', poster: 'consequences_of_abortion.png', title: '31.03.2012. Встреча с читателями. Освободиться от последствий аборта.' },
];
export const SeanceWater = [
    { id: 'Vgkvz84x', url: 'water.mp4', poster: 'water.png', title: '2006 год. Аудиосеминар по книге Неоднородная Вселенная. Зарядить воду.' },
    { id: 'XSVG00vL', url: 'how_to_use_water.mp4', poster: 'how_to_use_water.png', title: '1991 год. Семинар-выступление в Архангельске. Как пользоваться заряженной водой.' },
];

//const videoUrl = 'https://file.achird.ru/video/';
const videoUrl = 'https://achird.azureedge.net/video/';
const videoUnknown = { url: 'none', poster: 'none', title: 'Видеозапись с таким идентификатором не существует' };

export function VideoList(props) {
    let url = videoUrl;

    return (
        <Fragment>
            <Row>
                <Col xl="12" lg="12" className="mb-2">
                    <div className="pl-0 pl-lg-3">
                        <h4 className="text-second font-weight-bold">
                            {props.title}
                        </h4>
                    </div>
                </Col>
                {props.items.map((watch, pos) => (
                    <Col key={pos.toString()} xl="4" lg="6">
                        <Link tag="a" to={'/watch/' + props.link + '/' + watch.id}>
                            <Card className="card-box mb-4">
                                <img alt={watch.title} className="card-img-top" src={url + watch.poster} />
                                <div className="card-footer d-flex py-3">
                                    {watch.title}
                                </div>
                            </Card>
                        </Link>
                    </Col>
                ))}
            </Row>
        </Fragment>
    );
}

export class Watch extends Component {
    constructor(props) {
        super(props);
        console.dir(props);
        this.state = {
            url: videoUrl,
            link: props.match.params.link || '',
            video:
                VideoMeetings
                    .concat(VideoEvents)
                    .concat(VideoPresentations)
                    .concat(VideoInterview)
                    .concat(VideoInteresting)
                    .concat(VideoFilming)
                    .concat(SeanceWellness)
                    .concat(SeanceBrainTransformation)
                    .concat(SeanceRemovingLocks)
                    .concat(SeanceWater)
                    .find(x => x.id === props.match.params.id) || videoUnknown,
        }
    }

    componentDidMount() {
        Seo({ title: this.state.video.title });
    }

    render() {

        const videotag = () => {
            if (this.state.video !== videoUnknown) {
                return (
                    <video className="mt-3" src={this.state.url + this.state.video.url} poster={this.state.url + this.state.video.poster} width="100%" height="auto" controls />
                );
            }
        }

        return (
            <Fragment>
                <Row>
                    <Col xl="10" lg="12">
                        <h5>{this.state.video.title}</h5>
                        {videotag()}
                        <Link to={'/' + this.state.link}>
                            <Button className="mt-3" color="primary">
                                <span>Назад</span>
                            </Button>
                        </Link>
                    </Col>
                </Row>
            </Fragment>
        );
    }
}