import React, { Fragment, Component } from 'react';
import { UncontrolledCarousel, Row, Col, Card, Button, Modal } from 'reactstrap';
import { PageTitle } from '../layout-components/PageTitle';
import { Seo } from '../seo'

export class Painting extends Component {
    constructor(props) {
        super(props);
        let url = 'https://achird.azureedge.net/images/';
        this.state = {
            modal: false,
            items: [
                { src: url + 'painting_image01.jpg', altText: '«Приход»', key: '01' },
                { src: url + 'painting_image02.jpg', altText: '«Исход»', key: '02' },
                { src: url + 'painting_image03.jpg', altText: '«Отражение-1»', key: '03' },
                { src: url + 'painting_image04.jpg', altText: '«Отражение-2»', key: '04' },
                { src: url + 'painting_image05.jpg', altText: '«Рождение Жизни»', key: '05' },
                { src: url + 'painting_image06.jpg', altText: '«Семена Разума»', key: '06' },
                { src: url + 'painting_image07.jpg', altText: '«Феона»', key: '07' },
                { src: url + 'painting_image08.jpg', altText: '«Ревена»', key: '08' },
                { src: url + 'painting_image09.jpg', altText: '«Прорыв»', key: '09' },
                { src: url + 'painting_image10.jpg', altText: '«Иисус»', key: '10' },
                { src: url + 'painting_image11.jpg', altText: '«Близнец-1»', key: '11' },
                { src: url + 'painting_image12.jpg', altText: '«Близнец-2»', key: '12' }
            ]
        };
    }

    componentDidMount() {
        Seo({
            title: 'Галерея Николая Левашова'
        });
    }

    render() {
        const toggle = () => this.setState({ modal: !this.state.modal });

        function Quote(props) {
            return (
                <span>
                    <Button color="primary" onClick={props.toggle} className="font-weight-bold">
                        <small>
                            Начать просмотр
                        </small>
                    </Button>
                </span>
            );
        }

        function Art(props) {
            return (
                <Col xl="4" lg="6">
                    <Card className="mb-4">
                        <img alt={props.title} className="card-img-top" src={props.image} />
                        <h6 className="ml-3 mt-3 mb-3 text-center">{props.title}</h6>
                    </Card>
                </Col>
            );
        }

        return (
            <Fragment>
                <PageTitle
                    titleHeading="Живопись"
                    titleDescription="Здесь представлены работы Николая Левашова"
                    titleQuote={<Quote toggle={toggle} />}
                />
                <Row>
                    {this.state.items.map((art, pos) => (
                        <Art key={pos.toString()} image={art.src} title={art.altText} />
                    ))}
                </Row>
                <Modal zIndex={2000} centered size="md" isOpen={this.state.modal} toggle={toggle}>
                    <UncontrolledCarousel items={this.state.items} />
                </Modal>
            </Fragment>
        );
    }
}
