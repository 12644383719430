import React, { Fragment } from 'react';
import { Verse } from './Verse';

export function VerseRevelationsOfTheUniverses() {
    return (
        <Verse
            title="Откровения Вселенных"
            description="Николай Левашов, 2000 год."
            content={
                <Fragment>
                    <div className="mt-2 text-center">
                        Скажи мне, в чём познанья счастье?<br/>
                        Скажи мне, в чём величие души?<br/>
                        Не в том ли чувстве ожиданья,<br/>
                        Что всё, конечно, впереди...
                        <p />
                        Передо мной конечность, бесконечность,<br/>
                        Сливаясь воедино, промелькнут,<br/>
                        Раскроет тайну свою вечность,<br/>
                        И сброшу, наконец, я бремя пут.
                        <p />
                        Душа, открытая Познанью,<br/>
                        Достигнет высшей красоты,<br/>
                        И сам приду я к пониманью<br/>
                        Своей несбыточной мечты.
                        <p />
                        Нельзя объять, что необъятно,<br/>
                        Но не стремиться нам нельзя.<br/>
                        Всё ясно, вроде бы, понятно,<br/>
                        А что понятно — и понять нельзя.
                        <p />
                        Свой хрупкий чёлн в познанья океан<br/>
                        Я бросил смело, полный дерзновенья,<br/>
                        И получил я всё, что ожидал,<br/>
                        И сверх того — Вселенных откровенья.
                        <p />
                        Я Высший Разум встретил на пути,<br/>
                        И прикоснулся к тайне мирозданья,<br/>
                        И испытанья, от которых не уйти,<br/>
                        Когда достичь желаешь пониманья...
                        <p />
                    </div>
                </Fragment>
            }
        />
    );
}