import React, { Fragment } from 'react';
import { Verse } from './Verse';

export function VerseYouAreMyBelovedOne() {
    return (
        <Verse
            title="Ты моя любимая..."
            description="Николай Левашов, 19 декабря 2011 года."
            content={
                <Fragment>
                    <div className="mt-2 text-center">
                        Ты моя любимая,<br/>
                        Ты моя душа.<br/>
                        И любовь к тебе моя<br/>
                        В сердце будет вечная.
                        <p />
                        Нас с тобой судьба свела,<br/>
                        Навсегда – не временно.<br/>
                        Звёздная тропа вела,<br/>
                        Сквозь пространства, времена!
                        <p />
                        И пускай теперь опять,<br/>
                        Между нами пропасти!<br/>
                        Но не в силах разорвать,<br/>
                        Наше чувство напасти!
                        <p />
                        Нет преграды для любви,<br/>
                        Если в ней душа.<br/>
                        А душа с душой всегда<br/>
                        Будет вечно связана!
                        <p />
                        И хотя я не Орфей,<br/>
                        Но готов отправиться<br/>
                        В Царство Мёртвых за тобой<br/>
                        И с задачей справиться.
                        <p />
                        Нужно только отыскать<br/>
                        Ключ от Царства Мёртвого.<br/>
                        И без страха открывать,<br/>
                        Грань из мира плотного.
                        <p />
                        Где же ключик тот найти?<br/>
                        Где же он скрывается?<br/>
                        Чтобы пропасть перейти,<br/>
                        За тобой отправиться.
                        <p />
                        Руку дам тебе свою,<br/>
                        За собою поведу!<br/>
                        Изменю судьбу твою,<br/>
                        В мир живых тебя верну.
                        <p />
                        Где же ключик тот найти!?<br/>
                        Без него не справиться!<br/>
                        Буду я его искать,<br/>
                        На себя надеяться...
                        <p />
                        Лишь бы жизни у меня<br/>
                        Было бы достаточно,<br/>
                        Чтоб вернуть назад тебя<br/>
                        В мир живых желательно.
                        <p />
                        Если жизни у меня<br/>
                        Мало вдруг окажется,<br/>
                        Не смогу вернуть тебя,<br/>
                        Сам к тебе отправлюсь я!
                        <p />
                        Вместе будем мы опять,<br/>
                        Так или иначе!<br/>
                        И судьбу свою опять<br/>
                        Сотворим тем паче...
                        <p />
                        Встретим рок судьбы своей<br/>
                        Мы с тобой достойно!<br/>
                        Но мириться с ней<br/>
                        Никогда не сможем...
                        <p />
                    </div>
                </Fragment>
            }
        />
    );
}