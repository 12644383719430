import React, { Fragment } from 'react';
import { Verse } from './Verse';

export function VerseSleepingRussia() {
    return (
        <Verse
            title="Спящая Россия"
            description="Николай Левашов, 15 июня 2008 года."
            content={
                <Fragment>
                    <div className="mt-2 text-center">
                        Душа России спит сейчас,<br/>
                        Ещё не грянул светлый час.<br/>
                        Ещё дурмана власть сильна,<br/>
                        И смертный враг силён пока!
                        <p/>
                        Но где же дух, что Русью пахнет!?<br/>
                        Где те сыны, в ком этот дух живёт!?<br/>
                        Где те, кто ей свободу принесёт<br/>
                        И в губы спящей жизнь вдохнёт!?
                        <p />
                        Неужто все слепыми стали,<br/>
                        Не могут правду-кривду отличить!<br/>
                        Неужто все рабами стали,<br/>
                        И жаждут в рабстве жизнь влачить!?
                        <p />
                        Сыны России – сбросьте наважденье!<br/>
                        Проснитесь русы – Вас величье ждёт!<br/>
                        И сбросит путы Русь светая,<br/>
                        И вновь величье обретёт!
                        <p />
                        Сердца горячие и разум светлый<br/>
                        Способны мир освободить,<br/>
                        И дух свободный возродить,<br/>
                        И честь, и совесть сохранить!
                        <p />
                        Русь на коленях долго простояла,<br/>
                        Пора вставать ей в полный рост!<br/>
                        Беда пока ещё не миновала, – <br/>
                        Тяжёлым игом был тысячелетний пост!
                        <p />
                        Могучих предков сила не пропала,<br/>
                        Но спит пока глубоким сном.<br/>
                        Сварога Ночь Русь миновала<br/>
                        И чистый свет вещает нам о том!
                        <p />
                        Примите свет, проснитесь русы,<br/>
                        В себе раскройте силы и добро.<br/>
                        Свободы свет боятся только трусы,<br/>
                        В ком совесть умерла давно!
                        <p />
                        Загадкой русская душа<br/>
                        Осталась для врагов навеки!<br/>
                        Загадкой Русь всегда была,<br/>
                        А в ней загадка – люди-человеки!
                        <p />
                        Те исполины, что всегда<br/>
                        Готовы всё отдать на свете,<br/>
                        Чтоб Родина была жива,<br/>
                        И были счастливы все дети...
                        <p />
                    </div>
                </Fragment>
            }
        />
    );
}