import React from 'react';
import { Link } from 'react-router-dom'
import { Row, Col } from 'reactstrap';

import error404 from '../assets/images/illustrations/404.svg';

export default function PageError404() {
  return (
    <>
      <div className="app-wrapper bg-white">
        <div className="app-main">
          <div className="app-content p-0">
            <div className="app-inner-content-layout--main">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content">
                  <div className="hero-wrapper bg-composed-wrapper min-vh-100">
                    <div className="flex-grow-1 w-100 d-flex align-items-center">
                      <Col lg="6" md="9" className="px-4 px-lg-0 mx-auto text-center text-black">
                        <img src={error404} className="w-50 mx-auto d-block my-5 img-fluid" alt="..." />
                        <h3 className="font-size-xxl line-height-sm font-weight-light d-block px-3 mb-3 text-black-50">
                            Страницы не существует
                        </h3>
                        <div className="text-center">
                            Скорее всего страница была перемещена.
                            Попробуйте перейти на  
                            {' '}
                            <Link to="/">
                                <a className="btn-link-primary">
                                    <span><b>главную страницу сайта</b></span>
                                </a>
                            </Link>
                        </div>
                      </Col>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
