import React, { Fragment } from 'react';
import { CardBody, Card, Col } from 'reactstrap';

export function Verse(props) {
    return (
        <Fragment>
            <Col xl="4" lg="6" md="6">
                <Card className="card-box mb-4">
                    <div className="card-header bg-light">
                        <div className="card-header--title text-center">
                            <b>{props.title}</b>
                        </div>
                    </div>
                    <CardBody className="mt-2 p-0">
                        {props.content}
                    </CardBody>
                    <div className="divider" />
                    <h6 className="alert-heading mt-3 mb-3 ml-4 mr-4 text-center"><cite>{ props.description }</cite></h6>
                </Card>
            </Col>
        </Fragment>
    );
}