import React from 'react';
import PageError404 from '../layout-components/PageError404';

export function Page404() {
    return (
        <PageError404 />
    );
}

export default Page404;
