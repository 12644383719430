import React, { Fragment, Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import MetisMenu from 'react-metismenu';
import RouterLink from './ReactMetismenuRouterLink';

const sidebarMenuContent = [
    { label: 'Вступление', icon: 'pe-7s-home', description: 'Обращение к читателю', to: '/' },
    { label: 'Рекомендации', icon: 'pe-7s-map-marker', description: 'Рекомендации и пожелания Николая Левашова', to: '/advices' },
    { label: 'Книги и статьи', icon: 'pe-7s-notebook', description: 'Книги и статьи Николая Левашова', to: '/books' },
    { label: 'Видеофильмы', icon: 'pe-7s-film', description: 'Экранизации знаний Николая Левашова', to: '/filming' },
    { label: 'Видеосеансы', icon: 'pe-7s-video', description: 'Видеосеансы Николая Левашова', to: '/seance' },
    { label: 'Видеозаписи', icon: 'pe-7s-album', description: 'Видеозаписи с участием Николая Левашова', to: '/video' },
    { label: 'Источник Жизни', icon: 'pe-7s-leaf', description: 'Генератор пси-поля Николая Левашова, названный Источником Жизни', to: '/source' },
    { label: 'Живопись', icon: 'pe-7s-photo', description: 'Картины Николая Левашова, нарисованные в Adobe Photoshop', to: '/painting' },
    { label: 'Стихи', icon: 'pe-7s-pen', description: 'Стихи Николая Левашова', to: '/verses' },
];

export class SidebarMenu extends Component {
    render() {
        return (
            <Fragment>
                <PerfectScrollbar>
                    <div className="sidebar-navigation">
                        <div className="sidebar-header">
                            <span>Путеводитель по сайту</span>
                        </div>
                        <MetisMenu
                            content={sidebarMenuContent}
                            LinkComponent={RouterLink}
                            activeLinkFromLocation
                            iconNamePrefix=""
                            noBuiltInClassNames={true}
                            classNameItemActive="active"
                            classNameIcon="sidebar-icon"
                            iconNameStateVisible="sidebar-icon-indicator"
                            iconNameStateHidden="sidebar-icon-indicator"
                            classNameItemHasVisibleChild="submenu-open"
                            classNameItemHasActiveChild="active"
                            classNameStateIcon="pe-7s-angle-down"
                        />
                    </div>
                </PerfectScrollbar>
            </Fragment>
        );
    }
}
