import { makeAutoObservable  } from "mobx";

class SidebarStore {
    constructor() {
        makeAutoObservable(this);
    }

    sidebarToggleMobile = false;

    toggleSidebarMobile = () => {
        this.sidebarToggleMobile = !this.sidebarToggleMobile;
    }
}

export const sidebarStore = new SidebarStore();

