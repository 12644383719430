import React, { Component, Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import { PageTitle } from '../layout-components/PageTitle';
import { Seo } from '../seo'

export class Advices extends Component {
    componentDidMount() {
        Seo({ title: 'Рекомендации для читателей от Николая Левашова' });
    }

    render () {
        function Quote() {
            return (
                <p className="text-justify">
                    Здесь содержатся рекомендации и предпочтения автора. Эти рекомендации – совершенно необязательны
                    для изучения или следования им. Они даются исключительно для сведения читателей, которые,
                    проявляя свою свободную Волю, могут всегда делать всё, что им заблагорассудится!
                </p>
            );
        }

        return (
            <Fragment>
                <PageTitle
                    titleHeading="Рекомендации"
                    titleDescription={ <Quote/> }
                />

                <Fragment>
                    <Row>
                        <Col xl="12" lg="12" className="text-justify">
                            <h4 className="alert-heading">Как создать защиту?</h4>
                            <hr />
                            <p>
                                Каждый человек имеет пси-поле вокруг себя. Конечно, у разных людей оно
                                имеет разную структуру, плотность, силу. Имеются общие элементы и особенности.
                                Первое, что необходимо сделать каждому – это научиться создавать и управлять
                                своим защитным полем. Для этого человек должен выработать у себя способность
                                создания защитного поля на уровне подсознания. Как это делается? Человек должен
                                сконцентрироваться на мысли: «Вокруг меня создаётся из моей энергии защитный кокон (оболочка),
                                через который не может проникнуть отрицательная энергия». Такая установка должна делаться каждую
                                свободную минуту до тех пор, пока мозг не выработает условный рефлекс на защиту.
                            </p>
                            <p>
                                Далее нужно лишь периодически проверять состояние и мощность этой защиты. Когда человек
                                находится в большом скоплении людей, необходимо создавать максимальную защиту. В своём доме
                                она может быть минимальной. Очень важно создавать защиту перед сном как для физического тела,
                                так и для сущности. При создании такой защиты как вокруг тела, так и вокруг сущности образуется
                                энергетический кокон. Если защита создана правильно, возможность её разрушения становится
                                минимальной. Что очень важно для сохранения целостности защиты, так это отсутствие у человека
                                отрицательных эмоций.
                            </p>
                            <p>
                                Дело в том, что они открывают защиту изнутри, создавая при этом потоки энергии, по составу и
                                качеству тождественные уровню нижнего астрала. Это создаёт благоприятные условия для действия
                                астральных животных, а также и для любого другого пси-энергетического воздействия на человека.
                                Именно о недопустимости отрицательных эмоций говорил Иисус Христос. Он не призывал к безропотности,
                                а наоборот, всей своей жизнью доказывал необходимость борьбы. Он знал, что если при борьбе со злом
                                возникают отрицательные эмоции, даже если они направлены на источник зла, человек в конечном счёте
                                или будет побеждён этим злом, или со временем сам станет источником зла. Именно в этом сила зла.
                                Для борьбы с ним необходимо сохранить свою эмоциональную чистоту, без которой невозможно развитие и
                                борьба со злом...
                            </p>
                            <h4 className="alert-heading">Как очистить продукты?</h4>
                            <hr />
                            <p>
                                Кроме защиты, хотелось бы обратить внимание на возможность энергетической очистки продуктов питания.
                                Каждый человек пьёт воду, соки, потребляет пищу, необходимую для нормальной жизнедеятельности организма.
                                Но любая пища, как растительная, так и мясная, содержит органические яды, которые ускоряют процесс
                                разрушения организма и замедляют развитие. Поэтому, необходимо нейтрализовать, расщепить эти яды.
                                Для этого представьте, как ваша энергия начинает активно двигаться по энергетическим каналам Вашего тела.
                                И далее мысленно направляйте энергию через руки на пищу, которую вы хотите очистить.
                            </p>
                            <p >
                                При этом Вы должны представить, как она расщепляет все яды в пище. При движении энергии через руки,
                                Вы можете чувствовать вибрацию, тепло, покалывание, и чем активней процесс расщепления ядов,
                                тем более яркие и сильные ощущения вы получите. Таким же образом Вы можете очищать воду, соки,
                                любые другие жидкости.
                            </p>
                            <p>
                                Кроме нейтрализации отрицательных для организма веществ, свойств, можно усилить то положительное,
                                что несут в себе эти вещества и создать новые свойства и качества. Если Вы при этом в состоянии
                                визуализировать эти потоки, значит Вы имеете повышенную чувствительность и, при правильном развитии,
                                можете довольно быстро достичь более высокого уровня развития своей сущности, сознания.
                            </p>
                            <hr />
                            <p>
                                И мне хочется пожелать всем встать на правильный путь развития, снять «чёрную» повязку с глаз, и Вы увидите, как действительно прекрасен мир, природа, Вселенная...
                            </p>
                        </Col>
                    </Row>
                </Fragment>
            </Fragment>
        );
    }
}
