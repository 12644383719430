import React, { Fragment } from 'react';
import { Verse } from './Verse';

export function VerseHeartBleeding() {
    return (
        <Verse
            title="Сердце, истекая кровью"
            description="Николай Левашов, 10 июня 2011 года."
            content={
                <Fragment>
                    <div className="mt-2 text-center">
                        Сердце, истекая кровью,<br/>
                        Никогда не будет лгать.<br/>
                        Очищая душу болью,<br/>
                        Чувства будет укреплять!
                        <p />
                        Ведь любовь не знает смерти,<br/>
                        Нет границы у любви!<br/>
                        И пускай бушуют черти,<br/>
                        И завидуют любви!
                        <p />
                        Ведь любовь есть душ касанье,<br/>
                        Душ касанье, а не тел!<br/>
                        И не страшно расстоянье,<br/>
                        И не страшна ей и смерть!
                        <p />
                        Смерть не в силах уничтожить,<br/>
                        Чувство дивное любви.<br/>
                        Только вечность ожиданья<br/>
                        И надежда впереди...
                        <p />
                        Ожиданье той минуты,<br/>
                        Когда вновь душа с душой.<br/>
                        Воспарят над миром этим<br/>
                        И отправятся домой.
                        <p />
                        А минута эта может,<br/>
                        Ой, как долго не прийти!<br/>
                        Оттого и сердце гложет,<br/>
                        Смыслу жизни вопреки.
                        <p />
                        Долг и совесть обязуют,<br/>
                        Попытаться сделать всё.<br/>
                        Перед тем, как миг наступит,<br/>
                        И душа пойдёт к тебе.
                        <p />
                        Ведь не можем мы оставить<br/>
                        Мир прекрасный весь во Тьме.<br/>
                        Пусть сгорим, но свет оставим,<br/>
                        Путь покажем всем во тьме!
                        <p />
                        Ведь тогда с душою лёгкой,<br/>
                        С чистой совестью своей,<br/>
                        Сможем мы с тобой, Светлана,<br/>
                        За судьбой своей пойти...
                        <p />
                    </div>
                </Fragment>
            }
        />
    );
}