import React, { Fragment } from 'react';
import { Verse } from './Verse'

export function VerseDedicatedToTheMemoryOfSvetlana() {
    return (
        <Verse
            title="Памяти Светланы посвящается"
            description="Николай Левашов, 14 ноября 2010 года 2 часа 42 минуты утра."
            content={
                <Fragment>
                    <div className="mt-2 text-center">
                        Слова любви тебе уже не скажешь,<br/>
                        Не бьётся сердце более твоё.<br/>
                        В твои глаза уж боле не заглянешь,<br/>
                        И не увидишь отражение своё.
                        <p/>
                        Враги взорвали сердце изнутри<br/>
                        И кровь по телу боле не струится.<br/>
                        И жизнь по капле из тебя<br/>
                        В бездонный холод уж стремится.
                        <p />
                        Ты была светом для меня<br/>
                        И этим светом душу согревала.<br/>
                        И сразу стало пусто без тебя<br/>
                        И мрак окутал чёрным покрывалом.
                        <p />
                        Враги не в силах были пережить,<br/>
                        Что ты как воин с ними воевала.<br/>
                        Ты так мечтала дальше жить,<br/>
                        Увидеть свет победы так мечтала.
                        <p />
                        Ты не искала пользы для себя<br/>
                        Ты жизнь свою по капле отдавала.<br/>
                        И самым главным было для тебя,<br/>
                        Чтоб жизнь и дальше процветала.
                        <p />
                        Ты мне во многом помогала,<br/>
                        Ты людям многое сама несла.<br/>
                        Душевным светом путь им освещала<br/>
                        И этим многим людям жизнь спасла.
                        <p />
                        Врагам сорвали этим летом планы.<br/>
                        Они хотели Родину сгубить<br/>
                        И миллионы жизней положить<br/>
                        И душу русскую убить.
                        <p />
                        Боится русов нечисть мировая,<br/>
                        Мечтает душу русскую убить,<br/>
                        И рабство в Мире воцарить,<br/>
                        И править, кровью запивая.
                        <p />
                        Не вышло у ворогов в этот раз,<br/>
                        И ты им тоже в этом помешала.<br/>
                        Взбесилась нечисть уж в который раз<br/>
                        И на тебе всю злобу отыграла.
                        <p />
                        Не в силах до меня достать,<br/>
                        Они решили на тебе<br/>
                        На всю катушку отыграться<br/>
                        Чтобы потом за мной с улыбкой наблюдать.
                        <p />
                        Чтоб наблюдать, как я страдаю<br/>
                        И как украдкою от всех<br/>
                        Слезу из глаз своих роняю<br/>
                        И проклинаю чёрных тех.
                        <p />
                        За смерть твою они заплатят!<br/>
                        И долго будут помнить день,<br/>
                        Когда решились на такое...<br/>
                        От них оставлю только тень.
                        <p />
                        Я дело наше не оставлю,<br/>
                        Все силы снова соберу,<br/>
                        И наплевав на чёрных травлю<br/>
                        Я до победы дело доведу.
                        <p />
                        У той победы имя Свет – <br/>
                        Твоё у той победы имя!<br/>
                        И ты зажгла для всех рассвет,<br/>
                        Зажгла... сама погаснув...
                        <p />
                    </div>
                </Fragment>
            }
        />
    );
}