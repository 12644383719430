import React, { Fragment, Component } from 'react';
import { Row } from 'reactstrap';
import { PageTitle } from '../layout-components/PageTitle';
import { VideoList, VideoPresentations, VideoMeetings, VideoEvents, VideoInterview, VideoInteresting } from './video/Watch';
import { Seo } from '../seo'

function Description() {
    return (
        <div className="text-justify">
            «Чем больше человек может, тем выше у него уровень персональной
            ответственности, хочет он этого или нет, нравится ему это или нет!»
        </div>
    );
}

export class Video extends Component {
    componentDidMount() {
        Seo({
            title: 'Видеозаписи Николая Левашова'
        });
    }

    render() {
        return (
            <Fragment>
                <PageTitle
                    titleHeading="Видеозаписи"
                    titleDescription={<Description />}
                />

                <Row className="no-gutters w-100">
                    <VideoList title='Интересное видео' link='video' items={VideoInteresting} />
                    <VideoList title='Семинары и выступления Николая Левашова' link='video' items={VideoPresentations} />
                    <VideoList title='Встречи с читателями' link='video' items={VideoEvents} />
                    <VideoList title='Встречи с учасниками движения' link='video' items={VideoMeetings} />
                    <VideoList title='Интервью Николая Левашова' link='video' items={VideoInterview} />
                </Row>
            </Fragment>
        );
    }
}