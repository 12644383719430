import React, { useState, Fragment } from 'react';
import { observer } from "mobx-react";
import clsx from 'clsx';

import { Sidebar } from './layout-components/Sidebar';
import { Header } from './layout-components/Header';
import { Footer } from './layout-components/Footer';
import { sidebarStore } from './layout-components/SidebarStore';

export const SidebarStoreContext = React.createContext(null);
export const Layout = observer((props) => {

    const [store] = useState(() => sidebarStore);

    return (
        <SidebarStoreContext.Provider value={store}>
            <Fragment>
                <div className={clsx('app-wrapper ',
                    { 'app-sidebar-fixed': !sidebarStore.sidebarToggleMobile },
                    { 'app-sidebar-mobile-open': sidebarStore.sidebarToggleMobile },
                    'app-header-fixed')}>
                    <div>
                        <Sidebar />
                    </div>
                    <div className="app-main">
                        <Header />
                        <div className="app-content">
                            <div className="app-content--inner">
                                <div className="app-content--inner__wrapper">
                                    {props.children}
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </Fragment>
        </SidebarStoreContext.Provider>
    );

});

export default Layout;