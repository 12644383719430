import React, { Fragment } from 'react';
import { Button } from 'reactstrap';

export function Footer() {
    return (
        <Fragment>
            <div className="app-footer text-black-50">
                <div className="app-footer--first mt-2 mb-2">
                    <Button
                        tag="a"
                        href="https://www.youtube.com/channel/UCa1cVlaCHlUjLra1-dSIcrw"
                        target="_blank"
                        size="sm"
                        color="outline-danger"
                        className="ml-3 btn-transition-none">
                        YouTube
                    </Button>
                    <Button
                        tag="a"
                        href="https://t.me/achirdru"
                        target="_blank"
                        size="sm"
                        color="outline-primary"
                        className="ml-3 mr-3 btn-transition-none">
                        Telegram
                    </Button>
                    {/*<Nav>*/}
                    {/*    <NavItem>*/}
                    {/*        <NavLink*/}
                    {/*            href="https://uifort.com/template/bamburgh-react-admin-dashboard-reactstrap-free"*/}
                    {/*            className="rounded-sm"*/}
                    {/*            target="_blank">*/}
                    {/*            Download now*/}
                    {/*        </NavLink>*/}
                    {/*    </NavItem>*/}
                    {/*    <NavItem>*/}
                    {/*        <NavLink*/}
                    {/*            href="https://uifort.com/template/bamburgh-react-admin-dashboard-reactstrap-pro"*/}
                    {/*            className="rounded-sm"*/}
                    {/*            target="_blank">*/}
                    {/*            View PRO Version*/}
                    {/*        </NavLink>*/}
                    {/*    </NavItem>*/}
                    {/*</Nav>*/}
                </div>
                <div className="app-footer--second">
                    <span>
                        Copyright © { ' ' }
                        <a href="https://levashov.ws/" target="_blank" title="levashov.ws">
                            Nicolai Levashov
                        </a>
                    </span>
                </div>
            </div>
        </Fragment>
    );
}
