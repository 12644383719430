import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom'
import { Row, Col } from 'reactstrap';
import { PageTitle } from '../layout-components/PageTitle';
import { Seo } from '../seo'

export class Home extends Component {
    componentDidMount() {
        Seo({});
    }

    render() {

        function Description() {
            return (
                <div className="text-justify">
                    Этот сайт содержит Знания. Уникальные Знания, которые до сих пор не были доступны всем желающим.
                    Это Знания о загадках Жизни, об устройстве Вселенной, о Сущности, Разуме и многом другом...
                    <p />
                    {' '}
                    <Link to="/books">
                        <a className="btn-link-primary">
                            <span><b>Книги</b></span>
                        </a>
                    </Link>
                    {', '}
                    <Link to="/filming">
                        <a className="btn-link-primary">
                            <span><b>видеофильмы</b></span>
                        </a>
                    </Link>
                    {', '}
                    <Link to="/seance">
                        <a className="btn-link-primary">
                            <span><b>видеосеансы</b></span>
                        </a>
                    </Link>
                    {', '}
                    <Link to="/video">
                        <a className="btn-link-primary">
                            <span><b>видеозаписи</b></span>
                        </a>
                    </Link>
                    {', '}
                    <Link to="/source">
                        <a className="btn-link-primary">
                            <span><b>публикации</b></span>
                        </a>
                    </Link>
                    {' и '}
                    <Link to="/painting">
                        <a className="btn-link-primary">
                            <span><b>картины</b></span>
                        </a>
                    </Link>
                    , выложенные на этом сайте, содержат Знания о принципах и механизме возникновения
                    Жизни на нашей и множестве других планет, о законах появления мышления и сознания,
                    о возникновении звёзд и планет, и о многом другом...
                </div>
            );
        }

        return (
            <Fragment>
                <PageTitle
                    titleHeading="Обращение к читателю"
                    titleDescription={<Description />}
                />

                <Row>
                    <Col xl="12" lg="12">
                        <div className="text-justify">
                            Мы все живём в интересное время, когда наукообразность современных представлений
                            уже не в состоянии скрыть спрятанное за ними невежество. Невежество, — удивится кто-то,
                            — о каком невежестве можно говорить, если весь мир буквально «нашпигован» информацией о
                            новых открытиях, явлениях, гипотезах и теориях и их практических воплощениях.
                            <p />
                            Прежде всего, давайте с Вами чётко определимся, что такое информация и что такое знание.
                            Многие могут сразу возразить: мол, зачем молоть воду в ступе, когда это одно и то же.
                            Родственность этих понятий может ввести в заблуждение, но, если на минуточку остановиться и
                            задуматься о значении этих двух слов, как и многих других, которыми мы все пользуемся ежедневно,
                            то минимальной нашей реакцией будет изумление. Мы все забыли, что каждое слово имеет своё значение,
                            а не только звучание, но об этом несколько позже. А пока, вернёмся к значению слов информация и знание.
                            <p />
                            <b>Информация</b> — это сообщение, полученное нами через органы чувств о происходящем вокруг и внутри нас.
                            <p />
                            <b>Знание</b> — осмысление и понимание происходящего вокруг и внутри нас.
                            <p />
                            Казалось бы, незначительное различие, но это только на первый взгляд. А на самом деле, эти два понятия
                            различаются как небо и земля. Знание есть ни что иное, как осмысленная и понятая нами информация,
                            полученная через органы чувств о происходящем вокруг и внутри нас. К сожалению, современная земная
                            цивилизация накопила огромный объём информации о происходящем вокруг и внутри нас, но осмысления и
                            понимания этой информации практически не произошло. Противоречие между объёмом информации и знанием
                            поставило нашу цивилизацию <b>на грань самоуничтожения</b>, даже не от того оружия, которое с такой
                            изобретательностью создал человек. Самоуверенное вмешательство человека в живой организм планеты,
                            без понимания законов природы, неизбежно приближает всех нас, вне зависимости от того, понимаем мы
                            или нет, что происходит вокруг нас, к вырождению человека, как вида и гибели экологической системы,
                            а вполне возможно, и самой жизни на этой прекрасной голубой планете.
                            <p />
                            Позиция «моя хата с краю, ничего не знаю», не поможет. Хотим мы этого или нет, понимаем или нет,
                            признаём или нет — все мы, без исключения, дышим одним воздухом, пьём воду, потребляем пищу.
                            И вне зависимости от того, какой человек: хороший или плохой, умный или не очень, богатый или бедный,
                            образованный или нет, атеист или верующий — все люди, вне зависимости от цвета их кожи,
                            национальности и представлений, подчиняются одним и тем же законам природы, нравятся они
                            нам или нет. Единственное спасение от такого финала есть ВЕ-РА (ВЕдать Ра), что означает
                            <b> просветление знанием</b>, которое может возникнуть только тогда, когда человек осмыслит и
                            поймёт информацию, доступную ему, через свои органы чувств.
                            <p />
                            Религия, которая ничего общего с верой не имеет, а представляет собой фанатичное,
                            слепое восприятие происходящего, как проявление божественной воли, удобна только для
                            власть держащих, не в состоянии остановить губительное проявление невежества человека
                            хотя бы потому, что сама является порождением того же самого невежества.
                            <p />
                            Причина же невежества — в том, что органы чувств человека развились и сформировались
                            у Homo Sapiens, как вида живой природы, в процессе адаптации к экологической нише,
                            которую он (человек) занимает. В этом их суть и предназначение, о чём почему-то забыли.
                            Мы все настолько привыкли к тому, что получаем через наши органы чувств, что начисто
                            забыли об их изначальном предназначении. Они (органы чувств) достаточны для оптимальной
                            адаптации к экологической нише, которую занимает человек, как вид живой природы,
                            что и создаёт иллюзию целостности и достаточности. Они достаточны для восприятия срединного мира,
                            мира между макрокосмосом и микрокосмосом.
                            <p />
                            В срединном мире мы наблюдаем за проявлением законов природы, которые рождаются на уровне
                            макрокосмоса и микрокосмоса. Всё, что доступно органам чувств человека, есть лишь верхушка
                            «айсберга» законов природы, большая часть которого спрятана под «поверхностью» макрокосмоса и
                            микрокосмоса. Единственный способ заглянуть под «поверхность» макрокосмоса или микрокосмоса —
                            это <b>расширить число органов чувств</b>, добавить к тем, что нам подарила природа, новые.
                            И чем большее число дополнительных к уже существующим органам чувств получится создать,
                            тем более цельную «картину» законов природы можно увидеть, тем ближе к оригиналу и тем
                            богаче будут краски картины мироздания, созданной нашим расширенным сознанием.
                            <p />
                            В результате подобного «погружения» в океан макрокосмоса и микрокосмоса и родилась в
                            моём сознании картина мироздания, изложенная в моих книгах. Прозрев сам, я хочу
                            поделиться со всеми желающими той невероятной красотой и простотой природы, которая
                            открылась моему сознанию во время моего «погружения» в океан мироздания. Феномен жизни из
                            необъяснимой загадки, как по мановению волшебной палочки, превращается в естественный и
                            неизбежный процесс эволюции материи, как таковой.
                            <p />
                            <b>Понятия души, реинкарнации, сознания, памяти</b> и многие-многие другие из категории философских,
                            теологических и теософских материализуются, в прямом и переносном смысле, и обретают неземную
                            красоту ясных и чётких представлений о реальных явлениях природы, Природы, частичкой,
                            наделённой сознанием, которой является каждый думающий человек.
                            <p />
                            В моих книгах
                            {' '}
                            <Link to="/books">
                                <a className="btn-link-primary">
                                    <span><b>«Последнее Обращение к Человечеству»</b></span>
                                </a>
                            </Link>
                            {', '}
                            <Link to="/books">
                                <a className="btn-link-primary">
                                    <span><b>«Неоднородная Вселенная»</b></span>
                                </a>
                            </Link>
                            {', '}
                            <Link to="/books">
                                <a className="btn-link-primary">
                                    <span><b>«Сущность и Разум. Том 1»</b></span>
                                </a>
                            </Link>
                            {', '}
                            <Link to="/books">
                                <a className="btn-link-primary">
                                    <span><b>«Сущность и Разум. Том 2»</b></span>
                                </a>
                            </Link>
                            {' '}
                            изложено моё <b>понимание картины мироздания</b> и нашего места в ней, как формы живой материи, обретшей разум.
                            Эти представления кардинально отличаются от всего того, чему каждого из нас учили в школе,
                            в университетах. Наши головы забивали постулатами (понятиями, принимаемыми без
                            каких-либо доказательств), которые все должны были принимать слепо. Появлялись всё новые и
                            новые постулаты, но ни один из старых никогда не был объяснён. Но ведь каждый
                            необъяснённый постулат — это ни что иное, как Господь Бог. Постулат тождественен Богу,
                            так как оба понятия принимаются без доказательств. И что всегда меня поражало, так это то,
                            что так называемые «учёные» в упор не желали этого видеть или предпочитали не видеть,
                            всегда снисходительно относясь к верующим в Бога, от которых практически ничем не отличались.
                            Вернее, отличались по одному пункту — верующие никогда не говорили, что они знают
                            Господа Бога, а только то, что они в него веруют. В любом случае, это честная позиция,
                            чего нельзя сказать об «учёных», говорящих, что они «знают», в то время, как на самом деле,
                            они <b>веруют в постулаты</b>, которые сами же и придумывают, чтобы прикрыть белые пятна своего невежества.
                            <p />
                            В своих книгах я предлагаю систему знаний, а не очередную религию.
                            Единственным постулатом в этой системе является положение о том, что материя
                            есть объективная реальность, данная нам в ощущениях. Другими словами то,
                            что материя существует, и мне кажется, что это единственное, что в действительности
                            не требует каких-либо доказательств. Может быть, я и не прав, но у меня нет никаких
                            оснований считать по-другому. Правда, в понятие о том, что такое материя,
                            я вкладываю гораздо более широкий смысл, чем большинство других. О том, какое
                            это представление, можно прочесть в моих книгах.
                            <p />
                            Благодаря этим представлениям, <b>загадка возникновения жизни исчезает</b>,
                            и появляется понимание гармонии эволюции материи, как естественного процесса
                            стремления оной к устойчивому состоянию. И результатом подобного стремления и
                            есть появление живой материи, эволюция которой рано или поздно приводит к появлению разума,
                            и, что самое важное, не только на Земле, но и на миллионах, миллиардах планет во Вселенной.
                            Жизнь есть неизбежный результат существования материи, а не какая-то аномалия,
                            возникшая на одной из планет с задворок одной из галактик.
                            <p />
                            Сущность, душа из категорий мистических переходят в категорию явлений эволюции живой материи.
                            <b> Душа — материальна</b>, как и наше тело, как материальны и мысли. Природа памяти,
                            сознания из категорий философских переходят в категорию свойств и качеств
                            сложноорганизованной живой материи. И так можно продолжать практически до бесконечности.
                            <b> Завеса тайн природы падает</b>, и нашему взору открывается потрясающая по своей
                            красоте картина мироздания, частью которой является и человек. Всё это, с моей
                            точки зрения, стоит некоторого напряжения серого вещества всех тех, кто желает осознавать себя,
                            как разумное существо, как Homo Sapiens, не только по названию, но и по сути.
                            <hr />
                            С наилучшими пожеланиями, Николай Левашов
                        </div>
                    </Col>
                </Row>
            </Fragment>
        );
    }
}
